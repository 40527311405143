<template>
  <div class="hello">
    <!-- <p>预览</p>
    <div class="before"></div> -->
    <input class="upload uploadBack" type="file" accept="image/*" capture="camera" @change="imgChange"/>
    <br>
    <el-button @click="sureSava">裁剪</el-button>
    <el-button style="margin: 30px auto;"  @click="rotateBtn">旋转</el-button>
    <div class="container">
      <div class="img-container">
        <img :src="imgSrc" ref="image" alt=""> 
      </div>
      
    </div>
  </div>
</template>

<script>
import common from "@/utils/common";
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  data() {
    return{
      myCropper: null,
      afterImg: '',
      imgSrc: '',
      imgUpload: '',
      imgBaseUrl:'',
      imgEndUrl: ''
      //require('@/assets/1.jpg')
    }
  },
  mounted(){
    this.init()
    if (process.env.NODE_ENV == 'production') {
      this.imgBaseUrl = window.location.origin + '/file/'
      this.imgEndUrl = '?Authorization=' + common.getItem('wtToken')
    } else {
      this.imgBaseUrl = 'http://124.70.167.116:20081' + '/file/'
      this.imgEndUrl = '?Authorization=' + common.getItem('wtToken')
    }
  },
  methods:{
    imgChange(e) {
      let file = e.target.files[0];
      if (!e.target.files.length) {
        return;
      }
      let _this = this;
      // 文件上传
      let reader = new FileReader();
      reader.onload = function (e) {
        let content= file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        img.onload = function() {
          console.log("压缩图片")

        }
      }
    },
    imgVerify() {
      return
      this.$refs.image.src = this.imgBaseUrl + data.filePath + this.imgEndUrl
    },
    init(){
      this.myCropper = new Cropper(this.$refs.image, {
        viewMode: 1,
        dragMode: 'move',
        cropBoxResizable :false, //是否允许拖动 改变裁剪框大小
        // cropBoxMovable :false, //是否允许拖动裁剪框
        aspectRatio: 1, //裁剪框比例 1：1
        //  guides :false,//裁剪框虚线 默认true有
        // preview: '.before',
        background: false,
        autoCropArea: 0.6, // 裁剪大小倍数
      })
    },
     sureSava(){
      this.afterImg = this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/jpeg')
      console.log(this.afterImg )
    },
    rotateBtn() {
      this.myCropper.rotate('90')
    }
  }
}
</script>

<style lang="less" scoped>
.flex{
  display: flex;
}
.container{
  display: flex;
}
.before{
  width: 100px;
  height: 100px;
  overflow: hidden;
  /* 这个属性可以得到想要的效果 */
}
.img-container{
  height: 400px;
  overflow: hidden;
}
.afterCropper{
  flex: 1;
  margin-left: 20px;
  border: 1px solid salmon; 
  text-align: center;
}
.afterCropper img{
  width: 150px;
  margin-top: 30px;
}
</style>